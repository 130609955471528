module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-instagram-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"panmacmillan.co.za","short_name":"panmacmillan.co.za","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"./src/pm_framework/assets/images/panmacmillan-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"376515bd757153b0b92e8a60aabfdad0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M4WZ84H","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/@panmacmillan/gatsby-theme-panmacmillan/gatsby-browser.js'),
      options: {"plugins":[],"kontentProjectId":"bf5c3f50-7e88-01d8-50b6-27143cb64359","displayFooterEmailSignUp":false,"displayArticleFooterEmailSignUp":false,"displayOneTrustFooterButton":false,"footerCopyrightLine":"&copy; Pan Macmillan South Africa 2025"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
